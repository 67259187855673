import React from "react";

const Header = (props) => {
  return (
    <header className="px-4 md:px-8 lg:px-32 py-4 select-none sticky top-0 bg-white/80 z-10">
        <div className="flex flex-row items-center justify-between gap-2.5">
          <div className="w-1/4">
            <a
              href="https://www.laminarprojects.com"
              target="_blank"
              rel="noreferrer"
              className="flex flex-row items-center gap-1 text-laminar-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3.5 w-3.5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
              <span className="text-sm font-lato font-semibold hidden sm:block">
                laminarprojects.com
              </span>
            </a>
          </div>
          <div className="h-16">
            <img
              className="h-full m-auto"
              src="laminar-main-logo.png"
              alt="Laminar logo"
            />
          </div>
          <div className="flex flex-row justify-end w-1/4" onClick={props.startShare}>
            <div className="flex flex-row items-center gap-2.5 text-gray-500 border border-gray-300 rounded-lg w-min px-2.5 py-1.5 cursor-pointer shadow-sm bg-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
              </svg>
              <span className="font-inter font-medium text-xs text-gray-700 hidden xs:block">
                Share
              </span>
            </div>
          </div>
        </div>
    </header>
  );
};

export default Header;
