import React from "react";

const PrimaryButton = (props) => {
  return (
    <button
      className={`bg-laminar-blue-500 hover:bg-laminar-blue-700 focus:bg-laminar-blue-500 py-4 px-8 text-white font-medium rounded-md font-lato ${props.styles}`}
      disabled={props.disabled}
      onClick={props.clicked}
    >
      {props.children}
    </button>
  );
};

export default PrimaryButton;
