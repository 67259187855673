import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Widget } from "@typeform/embed-react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Survey = () => {
  let navigate = useNavigate();
  const handleSubmit = ({ responseId }) => {
    navigate(`/survey/${responseId}`);
  };
  useEffect(() => {
    localStorage.setItem("canRequest", true);
    setTimeout(() => {
      toast("You can track your progress at the top of the screen☝️");
    }, 2000);
  }, []);
  return (
    <div className="h-full">
      <ToastContainer
        position="top-center"
        autoClose={false}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Widget
        id={process.env.REACT_APP_TYPEFORM_FORM_ID_PROD}
        style={{ width: "100%", height: "100%", margin: "0" }}
        className="my-form"
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default Survey;
