import React from "react";

const BodyContainer = (props) => {
  return (
    <div className="h-full min-h-max flex flex-col items-center justify-center gap-4 mx-auto px-4 pb-4 max-w-screen-sm lg:max-w-screen-md">
      {props.children}
    </div>
  );
};

export default BodyContainer;
