import React from "react";

const Paragraph = (props) => {
  return (
    <p className={`text-xl text-center text-gray-700 font-medium font-lato ${props.styles}`}>
      {props.children}
    </p>
  );
};

export default Paragraph;
