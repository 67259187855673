import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Main from "../../components/UI/Main/Main";
import BodyContainer from "../../components/UI/BodyContainer/BodyContainer";
import Title from "../../components/UI/Title/Title";
import Tagline from "../../components/UI/Tagline/Tagline";
import Paragraph from "../../components/UI/Paragraph/Paragraph";
import PrimaryButton from "../../components/UI/Buttons/PrimaryButton";
import ShareButton from "../../components/UI/Buttons/ShareButton";
import InformationCircle from "../../components/Icons/InformationCircle";
import Refresh from "../../components/Icons/Refresh";
import ShareForm from "../../components/UI/Share/ShareForm";
import { useParams } from "react-router-dom";

const CompletedSurvey = () => {
  const [showShareModal, setShowShareModal] = useState(false);
  const onshowShareModal = () => {
    setShowShareModal(true);
  };
  const onCloseShareModal = () => {
    setShowShareModal(false);
    document.body.style.overflow = "unset";
  };
  let shareModal;
  if (showShareModal) {
    shareModal = <ShareForm close={onCloseShareModal} />;
    document.body.style.overflow = "hidden";
  }
  let navigate = useNavigate();
  let params = useParams();
  const url = `${process.env.REACT_APP_API_TARGET_BASE_URL}/survey/new`;
  const [emailAddress, setEmailAddress] = useState(null);
  useEffect(() => {
    const canRequest = localStorage.getItem("canRequest");
    if (canRequest) {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          responseToken: params.id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.result) {
            setEmailAddress(data.to.email);
            localStorage.removeItem("canRequest");
          } else {
            console.log("error", data);
          }
        });
    }
  }, []);

  const onRestartSurvey = () => {
    localStorage.removeItem("canRequest");
    navigate("/");
  };

  return (
    <div className="w-full smh:h-full smh:flex smh:flex-col">
      {shareModal}
      <Header startShare={onshowShareModal} close={onCloseShareModal} />
      <Main>
        <BodyContainer>
          <div className="flex flex-col gap-2">
            <Title styles="text-4xl xs:text-5xl text-gray-dark">
              Congratulations!
            </Title>
            <Tagline>You are one step closer to resolving your claim.</Tagline>
          </div>
          <Paragraph>
            Irrespective of your results, we have a playbook that will
            strengthen your business going forward, and we can't wait to get
            started. Quickly secure an early engagement meeting via the Calendly
            link below, and we'll be working towards maximising your claim
            recovery before you know it.
          </Paragraph>
          <div className="flex flex-row items-center gap-3.5 bg-blue-dark-100 py-2.5 px-3.5 border border-blue-dark-200 rounded-md">
            <InformationCircle styles="h-5 w-5 text-blue-dark-400" />
            <p className="text-base break-words text-blue-dark-700 font-normal font-lato">
              We've sent an email to{" "}
              <strong>{emailAddress || "your email address"}</strong>,
              containing your Claim Strength Score. Allow up to 10 minutes. If
              you can't find it, check your spam folder, and don't forget to
              trust the sender!
            </p>
          </div>
          <Paragraph>
            Do you know anyone who would benefit from this tool? Feel free to
            share it with them.
          </Paragraph>
          <div className="flex flex-row items-center justify-center gap-4">
            <div onClick={onRestartSurvey}>
              <PrimaryButton styles="flex flex-row items-center gap-4 text-xl">
                <Refresh styles="block m-auto h-6 w-6" />
                <div>Restart Survey</div>
              </PrimaryButton>
            </div>
            <ShareButton startShare={onshowShareModal} />
          </div>
        </BodyContainer>
      </Main>
      <Footer />
    </div>
  );
};

export default CompletedSurvey;
