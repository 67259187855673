import React from "react";

const ShareButton = (props) => {
  return (
    <div className="flex flex-row items-center gap-4 text-gray-500  border border-gray-300 rounded-md w-min px-6 py-4 cursor-pointer shadow-sm bg-white" onClick={props.startShare}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
      </svg>
      <span className="font-inter font-medium text-xl text-gray-700 hidden xs:block">
        Share
      </span>
    </div>
  );
};

export default ShareButton;
