import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Main from "../../components/UI/Main/Main";
import BodyContainer from "../../components/UI/BodyContainer/BodyContainer";
import PrimaryButton from "../../components/UI/Buttons/PrimaryButton";
import Title from "../../components/UI/Title/Title";
import Tagline from "../../components/UI/Tagline/Tagline";
import ShareForm from "../../components/UI/Share/ShareForm";

const Home = () => {
  const [showShareModal, setShowShareModal] = useState(false);
  const onshowShareModal = () => {
    setShowShareModal(true);
  };
  const onCloseShareModal = () => {
    setShowShareModal(false);
    document.body.style.overflow = 'unset';
  };
  let shareModal;
  if (showShareModal) {
    shareModal = <ShareForm close={onCloseShareModal} />;
    document.body.style.overflow = 'hidden';
  }
  const navigate = useNavigate();
  const onStartSurvey = (event) => {
    event.preventDefault();
    navigate("/survey/new")
  } 

  return (
    <div className="w-full smh:h-full smh:flex smh:flex-col select-none">
      {shareModal}
      <Header startShare={onshowShareModal} close={onCloseShareModal} />
      <Main>
        <BodyContainer>
          <div className="mx-auto">
            <div className="flex flex-col gap-2">
              <Title styles="text-5xl xs:text-6xl text-gray-dark">
                Claim Strength Calculator
              </Title>
            </div>
          </div>
          <div>
            <Tagline>
              We have developed a short questionnaire that will help you find
              out the likelihood of a successful commercial claim on your
              project
            </Tagline>
          </div>
          <Tagline styles="italic">
            As such, it is highly recommended that you complete this form
            ideally as a project team, or as a minimum, with one commercial and
            one operational representative.
          </Tagline>
          <div onClick={onStartSurvey} className="flex flex-col items-center">
            <PrimaryButton styles="text-xl">Get Started</PrimaryButton>
          </div>
        </BodyContainer>
      </Main>
      <Footer />
    </div>
  );
};

export default Home;
