import React from "react";

const SecondaryButton = (props) => {
  return (
    <button
      className={`border border-gray-300 bg-white py-4 px-8 text-gray-700 font-medium rounded-md font-lato ${props.styles}`}
      onClick={props.clicked}
    >
      {props.children}
    </button>
  );
};

export default SecondaryButton;
