import { Routes, Route, Navigate } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";
import Home from "./pages/Home/Home";
import NewSurvey from "./pages/NewSurvey/Survey";
import CompletedSurvey from "./pages/CompletedSurvey/CompletedSurvey";

function App() {
  return (
    <HttpsRedirect
      disabled={process.env.REACT_APP_DISABLE_HTTPS_REDIRECT === "true"}
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="survey/new" element={<NewSurvey />} />
        <Route path="survey/:id" element={<CompletedSurvey />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </HttpsRedirect>
  );
}

export default App;
