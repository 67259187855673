import React from "react";

const Footer = () => {
  return (
    <footer className="w-full bg-laminar-blue-dark px-4 py-8 md:py-12 xl:py-16 flex flex-row justify-center items-center flex-wrap gap-y-4 gap-x-12">
      <div className="flex flex-col text-center lg:text-left font-lato">
        <h3 className="text-2xl text-white font-semibold">
          Can't wait to speak with us and see how we can support your claim?
        </h3>
        <p className="text-lg text-light opacity-80 font-normal">
          Click the button to arrange a discovery call with our
          commercial/claims team now.
        </p>
      </div>
      <a
        href={process.env.REACT_APP_CALENDLY_LINK}
        target="_blank"
        rel="noreferrer"
        className="bg-light/10 flex flex-row items-center justify-center gap-4 py-3 w-full xs:w-max xs:px-8 xs:gap-8 rounded-md border border-white/20"
      >
        <img
          className="w-10 h-10"
          src="calendly-logo.png"
          alt="calendly-logo"
        />
        <div className="font-lato">
          <h4 className="text-lg font-semibold text-laminar-blue-500">
            Schedule time with our team
          </h4>
          <p className="text-base font-medium text-white">
            powered by Calendly
          </p>
        </div>
      </a>
    </footer>
  );
};

export default Footer;
