import React from "react";

const Main = (props) => {
  return (
    <main className="h-full min-h-max relative before:bg-cover before:bg-no-repeat before:bg-center before:opacity-70 before:bg-hero-pattern before:-z-50 before:absolute before:h-full before:w-full">
      {props.children}
    </main>
  );
};

export default Main;
