import React, { useState } from "react";
import Backdrop from "../Backdrop/Backdrop";
import ModalContainer from "../Modal/ModalContainer";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";

const ShareForm = (props) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const onNativeShare = () => {
    if (navigator.canShare) {
      navigator
        .share({
          title: "Laminar Claim Strength Calculator",
          text: "Click to try Laminar's Claim Strength Calculator",
          url: "https://claims.laminarprojects.com",
        })
        .then(() => {
          props.close();
        })
        .catch((error) => console.log("Sharing failed", error));
    }
  };
  const onCopyUrl = () => {
    navigator.clipboard.writeText("https://claims.laminarprojects.com");
    setCopySuccess(true);
  };

  let copyIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
      <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
    </svg>
  );

  if (copySuccess) {
    copyIcon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
      </svg>
    );
  }
  return (
    <>
      <ModalContainer>
        <div className="flex flex-row justify-end h-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 translate-x-2/4 -translate-y-2/4 fill-gray-400"
            viewBox="0 0 20 20"
            onClick={props.close}
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="flex flex-col gap-1 font-lato mb-4">
          <h2 className="font-bold text-lg text-gray-900">Share</h2>
          <p className="font-normal text-sm text-gray-500">
            You can copy the link below, or share using other apps.
          </p>
        </div>
        <div className="border rounded-md border-gray-300 py-2.5 px-3.5 mb-3">
          <p className="text-gray-400 text-sm font-normal font-inter">
            https://claims.laminarprojects.com
          </p>
        </div>
        <div className="flex flex-row items-center justify-center gap-4">
          <SecondaryButton
            styles="text-sm flex flex-row justify-center gap-2 w-full py-2.5 px-0"
            clicked={onCopyUrl}
          >
            {copyIcon}
            {copySuccess ? "Link copied!" : "Copy link"}
          </SecondaryButton>
          {navigator.canShare ? (
            <PrimaryButton
              styles="text-sm flex flex-row justify-center gap-2 w-full py-2.5 px-0"
              clicked={onNativeShare}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
              </svg>
              Share
            </PrimaryButton>
          ) : null}
        </div>
      </ModalContainer>
      <Backdrop clicked={props.close} />
    </>
  );
};

export default ShareForm;
