import React from "react";

const ModalContainer = (props) => {
  return (
    <div
      style={{
        top: "50vh",
        left: "50vw",
        transform: "translate(-50%, -50%)",
      }}
      className="fixed z-50 bg-white px-6 py-6 w-11/12 max-w-sm rounded-lg max-h-full overflow-y-auto"
    >
      {props.children}
    </div>
  );
};

export default ModalContainer;
